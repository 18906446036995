import React, { useRef } from "react";
import styles from "../forms.module.scss";

function ImageRadio({ imageOptions, handleChange, currentValue }) {
  const quantityRef = useRef();

  function handleClick() {
    const quantity = imageOptions?.isQuantity
      ? `${quantityRef.current.value}-${imageOptions?.value}`
      : imageOptions?.value;

    // Check if the value already exists in the currentValue
    const currentValues = currentValue ? currentValue.split(", ") : [];
    const isAlreadySelected = currentValues.some((val) =>
      val.endsWith(imageOptions?.value)
    );

    let updatedValue;

    if (isAlreadySelected) {
      // Remove the value if it's already selected (toggle behavior)
      updatedValue = currentValues.filter(
        (val) => !val.endsWith(imageOptions?.value)
      );
    } else {
      // Append the new value
      updatedValue = [...currentValues, quantity];
    }

    handleChange(updatedValue.join(", "));
  }

  // Determine if the current option is active
  const isActive = currentValue
    ?.split(", ")
    .some((val) => val.endsWith(imageOptions?.value));

  return (
    <div
      className={`${isActive ? styles.activeRadio : ""} ${
        styles.radioImageWrapper
      }`}
      onClick={handleClick}
    >
      <img src={imageOptions?.image_url} alt="img" />
      <p>{imageOptions?.value}</p>
      {imageOptions?.isQuantity && (
        <input
          type="number"
          className={styles.countInput}
          defaultValue={1}
          ref={quantityRef}
          onChange={handleClick}
          min={1}
        />
      )}
    </div>
  );
}

export default ImageRadio;
